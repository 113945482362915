import React from "react";
import classNames from "classnames";

import {aplaBase, aplaContent, aplaDark, aplaLight, aplaWhite} from "./Apla.module.css";

type IAplaVariant = "dark" | "light" | "white";

interface IProps {
    applyOn: React.ReactNode;
    as?: React.ElementType;
    className?: string;
    overflow?: "hidden" | "scroll";
    variant?: IAplaVariant;
    onClick?: () => void;
}

export function Apla<T extends {}>(props: React.PropsWithChildren<IProps & T>) {
    const {as, overflow, variant, className, applyOn, children, ...restProps} = props;

    const Element = as || "div";
    const cn = classNames(getAplaClassNames(variant), className);

    return (
        <Element className={cn} {...restProps}>
            {applyOn}
            <div className={aplaContent} style={{overflow}}>
                {children}
            </div>
        </Element>
    );
}

export const getAplaClassNames = (variant = "dark" as IAplaVariant) => {
    const variantClass = variantToClassName[variant];
    return classNames(aplaBase, variantClass);
};

const variantToClassName = {
    dark: aplaDark,
    light: aplaLight,
    white: aplaWhite
};
import React from "react";
import classNames from "classnames";

import {aplaBase, aplaContent, aplaDark, aplaLight, aplaWhite} from "./Apla.module.css";

type IAplaVariant = "dark" | "light" | "white";

interface IProps {
    applyOn: React.ReactNode;
    as?: React.ElementType;
    className?: string;
    overflow?: "hidden" | "scroll";
    variant?: IAplaVariant;
    onClick?: () => void;
}

export function Apla<T extends {}>(props: React.PropsWithChildren<IProps & T>) {
    const {as, overflow, variant, className, applyOn, children, ...restProps} = props;

    const Element = as || "div";
    const cn = classNames(getAplaClassNames(variant), className);

    return (
        <Element className={cn} {...restProps}>
            {applyOn}
            <div className={aplaContent} style={{overflow}}>
                {children}
            </div>
        </Element>
    );
}

export const getAplaClassNames = (variant = "dark" as IAplaVariant) => {
    const variantClass = variantToClassName[variant];
    return classNames(aplaBase, variantClass);
};

const variantToClassName = {
    dark: aplaDark,
    light: aplaLight,
    white: aplaWhite
};
